/* ./src/index.css */
@tailwind base;
@tailwind components;

@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  p {
    @apply text-sm;
  }
}

.divider p {
  width: 100%;
  text-align: center;
  border-bottom: 1.5px solid;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.divider p span {
  padding: 0 10px;
  background: #fff;
}

* {
  scroll-behavior: smooth;
  /* For Firefox Scrollbars*/
  scrollbar-width: thin;
}

/* For Chrome and other browsers except Firefox Scrollbars*/

::-webkit-scrollbar:horizontal {
  height: 0.5em;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 10px;
}

:root {
  --swiper-navigation-color: #cecece;
  --swiper-navigation-size: 22px !important;
}

.drawer {
  width: 100% !important;
}

.analyseSidebar {
  max-width: 600px;
  min-width: 600px;
}

.analIframe {
  min-width: 620px;
  min-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}


/* url content */
.url-content {
  background-color: #8B8B8B;
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
  border-radius: 10px;
  padding: 8px;
}

/* url range input */
.range-wrap {
  position: relative;
  margin: 0 auto 3rem;
}

.range {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  /* background: linear-gradient('right', #5bbfe1, #78c2ed, #95c5f5, #b0c8fa, #c9cbfc, #d4c2f8, #e1b8f0, #eeade4, #f790c7, #fc72a0, #f95572, #ec3e3e); */
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  /* background: rgba(59,173,227,1); */
  /* background: -moz-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(59,173,227,1)), color-stop(25%, rgba(87,111,230,1)), color-stop(51%, rgba(152,68,183,1)), color-stop(100%, rgba(255,53,127,1)));
  background: -webkit-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -o-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%); */
  background: radial-gradient(99.82% 124038.45% at 0% 5.42%, rgba(255, 255, 255, 0.23) 0%, #109CF1 0%, rgba(165, 126, 248, 0.32) 50.23%, #E80000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3bade3 ', endColorstr='#ff357f ', GradientType=1);
  height: 14.4px;
  border-radius: 10px;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
}

.bubble {
  background: black;
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  margin-top: 21px;
  left: 50%;
  transform: translateX(-50%);
}

.bubble::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  top: -5px;
  left: 37%;
}

.range-labels {
  padding: 0;
  list-style: none;
  width: 100%;
}

.range-labels li {
  position: relative;
  float: left;
  width: 33.33%;
  text-align: center;
  color: black;
  font-size: 14px;
}

.center-line {
  position: absolute;
  bottom: -21px;
  font-weight: 900;
}

.card-email-highlight{
  box-sizing: border-box;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
border-radius: 10px;
border: 1.5px solid #FBBF24;
}

.card-person-highlight{
box-sizing: border-box;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
border-radius: 10px;
border: 1.5px solid #109CF1;
}

.card-loc-highlight{
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1.5px solid #96501D;
}
.card-ccnr-highlight{
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1.5px solid #FF3C5F;
}
.card-highlight{
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1.5px solid #6e6e6e;
}